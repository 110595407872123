import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants';
import { truncateText } from '../../../../services/appService';
import { CURRENT_LANG_KEY } from '../../../../actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './styles/index.scss';
import { Skeleton } from 'antd';

const AudioBookItem = ({ audioBookItem, type = 'book' }) => {
  const { id, name, name_vi, thumbnail } = audioBookItem || {};
  const link =
    type === 'book'
      ? routes.audio_book.detail.replace(':id', id)
      : routes.kidz_book.detail.replace(':id', id);
  return (
    <div className="audio-book-item-wrapper">
      <Link to={link}>
        <div className="cover-document-card-wrapper">
          {/* <img
            className="image-cover-document-card-wrapper"
            src={thumbnail}
            alt=""
            loading="lazy"
          /> */}
          <LazyLoadImage
            alt=""
            className="image-cover-document-card-wrapper"
            src={thumbnail} // use normal <img> attributes as props
            loading="lazy"
            placeholder={
              <Skeleton.Image className="image-cover-document-card-wrapper" />
            }
          />
        </div>
        <div className="content-document-card-wrapper">
          <p>
            {localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
              ? truncateText(name_vi, 35)
              : truncateText(name, 35)}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default AudioBookItem;
