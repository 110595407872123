import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { List } from 'antd';

import {
  getListAudioBookAction,
  getListKidzBookAction,
} from '../../actions/audio_book';
import AudioBookItem from './components/AudioBookItem/AudioBookItem';

import './styles/index.scss';
import AccountBreadcrumb from '../../components/AccountBreadcrumb/AccountBreadcrumb';
import AudioBookBanner from './components/AudioBookBanner';
import LicenseBanner from './components/AudioBookBanner/LicenseBanner';
import { CURRENT_LANG_KEY } from '../../actions';

const NOT_BUY_AUDIO = 'Not bought yet';

const AudioBookList = ({
  user,
  actions,
  loading,
  audioBookList,
  type = 'book',
}) => {
  useEffect(() => {
    if (type === 'book') actions.getListAudioBookAction();
    else actions.getListKidzBookAction();
  }, [type]);

  return (
    <div>
      {user?.id && <AccountBreadcrumb />}
      {user?.id &&
        user?.audio_license_expire === NOT_BUY_AUDIO &&
        type === 'book' && <AudioBookBanner />}
      {user?.id &&
        user?.audio_license_expire !== NOT_BUY_AUDIO &&
        type === 'book' && <LicenseBanner user={user} />}
      {audioBookList?.data?.map(item => (
        <div
          key={item?.id}
          className="w-full max-w-screen-xl mx-auto flex flex-col px-2 px-md-3 px-lg-5 px-xl-0 border-b mb-20"
        >
          <div className="pl-xl-0 mb-2 mb-xl-0">
            <p className="font-bold text-black md:text-md lg:text-lg xl:text-xl uppercase">
              {localStorage.getItem(CURRENT_LANG_KEY) === 'vi'
                ? item?.name_vi
                : item?.name}{' '}
              {type === 'book' ? '| AUDIOBOOKS' : ''}
            </p>
          </div>
          <List
            id="audio-book-list-wrapper"
            dataSource={item?.books}
            grid={{
              xs: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            pagination={{
              pageSize: 8,
              total: item?.books.length,
            }}
            loading={loading}
            renderItem={item => (
              <AudioBookItem
                key={item.id}
                audioBookItem={item}
                type={type}
              />
            )}
          />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.profile.data,
    loading: state.audioBook.loading,
    audioBookList: state.audioBook.audioBookList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators(
      {
        getListAudioBookAction,
        getListKidzBookAction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioBookList);
